import ProductServices from "@/services/ProductServices";

const state = () => ({
  products: {
    products: []
  },
  productLoading: true,
  loadMoreProductLoading: false,
  productsCatalog: [],
  productByBarcode: [],
  productSelected: {},
  productSelectedLoading: true,
  productSelectedQty: {},
  productCategory: [],
  productCategorySelected: '',
  productCategoryLoading: true,
  productVariant: [],
  variant: {}
})

const mutations = {
  SET_PRODUCTS(state, payload) {
    state.products = payload
  },
  ADD_PRODUCTS(state, payload) {
    state.products.products.push(...payload)
  },
  SET_PRODUCT_LOADING(state, payload) {
    state.productLoading = payload
  },
  SET_LOAD_MORE_PRODUCT_LOADING(state, payload) {
    state.loadMoreProductLoading = payload
  },
  RESET_PRODUCTS(state) {
    state.products = []
  },
  SET_PRODUCTS_CATALOG(state, payload) {
    payload.map(p => {
      state.productsCatalog.push(p)
    })
  },
  RESET_PRODUCTS_CATALOG(state) {
    state.productsCatalog = []
  },

  SET_PRODUCT_BY_BARCODE(state, payload) {
    state.productByBarcode = payload
  },

  SET_PRODUCT_CATEGORY(state, payload) {
    state.productCategory = payload
  },

  SET_SELECTED_PRODUCT_CATEGORY(state, payload) {
    state.productCategorySelected = payload
  },

  SELECT_PRODUCT(state, payload) {
    state.productSelected = payload
  },

  SET_SELECT_PRODUCT_LOADING(state, payload) {
    state.productSelectedLoading = payload
  },

  SELECT_PRODUCT_QUANTITY(state, payload) {
    state.productSelectedQty = payload
  },

  SELECT_VARIANT(state, payload) {
    const checkVariantExist = state.productVariant.filter(variant => {
      return payload.id === variant.id
    })

    if (checkVariantExist.length) {
      state.productVariant = state.productVariant.filter(variant => {
        return payload.id !== variant.id
      })
    } else {
      let index = -1
      state.productVariant.map((v, i) => {
        if (v.name === payload.name) {
          index = i
        }
      })
      if (index >= 0) {
        state.productVariant.splice(index, 1, payload)
      } else {
        state.productVariant.splice(payload.id - 1, 0, payload)
      }
    }
  },
  RESET_PRODUCT_VARIANT(state) {
    state.productVariant = []
  },
  VARIANT_RESULT(state, payload) {
    state.variant = payload
  },
  RESET_VARIANT(state) {
    state.variant = {}
  }
}

const actions = {
  getProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProducts(payload)
        .then(response => {

          if (response.data.status_code === 200 && response.data.data.current_page === 1) {
            commit("SET_PRODUCTS", response.data.data);
          } else {
            commit("ADD_PRODUCTS", response.data.data.products)
          }
          commit("SET_PRODUCT_LOADING", false)
          commit("SET_LOAD_MORE_PRODUCT_LOADING", false)

          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_SELECT_PRODUCT_LOADING", true)
      ProductServices.getProduct(payload)
        .then(response => {
          if (response.data.status_code === 200) {
            commit("SELECT_PRODUCT", response.data.data.product);
            commit("SET_SELECT_PRODUCT_LOADING", false)
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getProductsCatalog({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProducts(payload)
        .then(response => {
          if (response.data.message === "success") {
            if (payload.data.page == 1) {
              commit("RESET_PRODUCTS_CATALOG");
            }
            commit("SET_PRODUCTS_CATALOG", response.data.data);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getProductByBarcode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductByBarcode(payload)
        .then(response => {
          if (response.data.message === "success") {
            commit("SET_PRODUCT_BY_BARCODE", response.data.data);
          }
          resolve(response);
        })
        .catch(error => {
          // console.log(error);
          reject(error);
        });
    });
  },
  getProductCategory({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductCategory(payload)
        .then(response => {
          if (response.data.message === "success") {
            commit("SET_PRODUCT_CATEGORY", response.data.data);
            state.productCategoryLoading = false
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }
}

const getters = {
  productImageCombination(state) {
    if (Object.keys(state.productSelected).length) {
      if (Object.keys(state.variant).length) {
        return state.variant.images[0].url
      } else {
        let image = state.productSelected.variants.filter((v) => {
          if (v.main === true) {
            return v
          }
        })
        return image[0].images[0].url
      }
    }
  },
  productStockCombination(state) {
    if (Object.keys(state.productSelected).length) {
      if (Object.keys(state.variant).length) {
        return state.variant.stock
      } else {
        let stock = state.productSelected.variants.filter((v) => {
          if (v.main === true) {
            return v
          }
        })
        return stock[0].stock
      }
    }
  },
  productPriceCombination(state) {
    if (Object.keys(state.productSelected).length) {
      if (Object.keys(state.variant).length !== 0) {
        return state.variant
      } else {
        let price = state.productSelected.variants.filter((v) => {
          if (v.main === true) {
            return v
          }
        })
        return price[0]
      }
    }
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}