import VueRouter from 'vue-router';

import MainRoutes from '@/routes/router/index.js'

const router = new VueRouter({
    mode: 'history',
    routes: [
      ...MainRoutes,
    ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (localStorage.getItem('access-token')) {
      next()
    }else{
      next('/login')
    }
  }else{
    next()
  }
})

export default router;
