import { apiClientAuth } from "@/config";

export default {
  async addCart(payload){
    return apiClientAuth({
      method: "post",
      url: "api/cashier/carts/add/",
      ...payload
    });
  },
  async getCart(payload){
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/carts/"+payload.id,
      ...payload
    });
  },
  async editProductCart(payload) {
    return apiClientAuth({
      method: "post",
      url: "/api/cashier/carts/update/",
      ...payload
    })
  },
  async deleteProductCart (payload) {
    return apiClientAuth({
      method: "delete",
      url: "/api/cashier/carts/delete/",
      ...payload
    })
  },
  async emptyCart (payload) {
    return apiClientAuth({
      method: "delete",
      url: "/api/cashier/carts/empty/",
      ...payload
    })
  },
  async sendAdditionalCost(payload){
    return apiClientAuth({
      method: "get",
      url: "/api/cashier/carts/additional-cost/" + payload,
    });
  }, 
  async getCartbyQr(payload){
    return apiClientAuth({
      method: "post",
      url: "/api/v1/my-carts",
      ...payload
    });
  },
  async addOrder(payload) {
    return apiClientAuth({
      method: "put",
      url: "/api/v1/my-order",
      ...payload
    });
  }
}