export default {
  methods: {
    productHaveDetailPrice (product) {
        if (!product.min_qty_semi && !product.semi_price) {
            return false
        }

        return true
    },
    productPriceDetail(product) {
        if (product.min_qty_semi && product.semi_price) {
            if (product.min_qty_grocery && product.grocery_price) {
                if(product.quantity >= product.min_qty_semi && product.quantity < product.min_qty_grocery ) {
                    return {price: product.semi_price, price_type: 'Semi'}
                } else  if (product.quantity >= product.min_qty_grocery) {
                    return {price: product.grocery_price, price_type: 'Grosir'}
                }
            } else {
                if (product.quantity >= product.min_qty_semi) {
                    return {price: product.semi_price, price_type: 'Semi'}
                }
            }
        }
        return {price: product.price, price_type: ''}
    }
  }
}